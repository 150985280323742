<template>
    <UnitCategory
        :data="categoryList"
        v-model:categoryVisible="unitCategoryVisible"
        typeName="生产单元"
        @onSelect="onChangeUnitCategory"
    />
    <UserList
        v-model:userListVisible="userListVisible"
        @onSelect="onSelectUser"
        selectType="radio"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}生产单元`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            :model="formData"
            ref="formRef"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="生产单元编码"
                name="code"
                :rules="[
                    { required: true, message: '请输入生产单元编码' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :disabled="Boolean(upDateId)"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="生产单元名称"
                name="name"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入生产单元名称' },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="生产单元类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (unitCategoryVisible = true)"
                        @onDelete="() => onChangeUnitCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="父级生产单元"
                name="pId"
                :rules="[{ required: true, message: '请选择父级生产单元' }]"
            >
                <a-tree-select
                    v-model:value="formData.pId"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    allow-clear
                    tree-default-expand-all
                    :field-names="{
                        label: 'name',
                        value: 'id',
                    }"
                    :tree-data="parentUnitOptions"
                ></a-tree-select>
            </a-form-item>

            <a-form-item
                label="责任人"
                name="principalDisplayName"
                :rules="[{ required: true, message: '请选择责任人' }]"
            >
                <a-form-item>
                    <SelectInput
                        :title="formData.principalDisplayName"
                        :onClick="() => (userListVisible = true)"
                        :showDeleteIcon="false"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import {
    apiProductUnitIndexData,
    apiDataDictionaryTypes,
    apiProductUnitUpdate,
    apiProductUnitSave,
} from "@/api";
import Select from "@/components/Select";
import UnitCategory from "@/components/CategoryModal";
import UserList from "@/components/UserList";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        UnitCategory,
        DownOutlined,
        UserList,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            types: [],
            formRef: null,
            unitCategoryVisible: false,
            unitListVisible: false,
            userListVisible: false,
            parentUnitOptions: [],
            categoryList: [],
            formData: {
                id: "",
                name: "",
                code: "",
                categoryCode: "",
                categoryName: "",
                pId: "",
                principalUsername: "",
                principalDisplayName: "",
            },
        });

        const getTypes = async () => {
            let res = await apiDataDictionaryTypes();
            if (res.status === "SUCCESS") {
                state.types = res?.data;
            }
        };

        const handleCancel = () => {
            state.formRef.resetFields();
            state.formData.id = "";
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let fn = props.upDateId ? apiProductUnitUpdate : apiProductUnitSave;
            let res = await fn(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiProductUnitIndexData(id);
            if (res.status === "SUCCESS") {
                const { parentCandidate, categoryCandidate } = res?.data;
                state.parentUnitOptions = [parentCandidate];
                state.categoryList = categoryCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = res.data ? res.data?.productionUnit?.[key] : "";
                });
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangeUnitCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSelectUser = ([user]) => {
            state.formData.principalUsername = user?.username;
            state.formData.principalDisplayName = user?.displayName;
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getTypes();
                    getIndexData(newVal || 0);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeUnitCategory,
            onSelectUser,
        };
    },
});
</script>
