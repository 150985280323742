<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <UpDateModal
            v-model:upDateId="upDateId"
            @eventUpdateOk="
                () => {
                    getTabelData();
                    getTreeData();
                }
            "
        />
        <FilterBlock @search="search" />
        <div class="table-content" :style="{ height: isSelect ? 'auto' : '100vh' }">
            <div class="tree-list">
                <a-input-search
                    v-model:value="keyWords"
                    placeholder="请输入关键词以检索"
                    enter-button
                    @search="handleSearchTree"
                />
                <a-tree
                    :tree-data="treeData"
                    :fieldNames="{
                        title: 'name',
                    }"
                    @select="handleSelectTree"
                >
                    <template #title="{ name, key, code }">
                        <a-tooltip>
                            <template #title>【{{ code }}】{{ name }}</template>
                            【{{ code }}】{{ name }}
                        </a-tooltip>
                    </template>
                </a-tree>
            </div>
            <a-table
                :columns="comColumns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
                :row-selection="
                    isSelect
                        ? {
                              selectedRowKeys,
                              onChange: onSelectChange,
                              type: 'radio',
                          }
                        : null
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a
                                v-perm="'btn:workshop-model-edit'"
                                @click="() => openUpDateModal(record.id)"
                            >
                                编辑
                            </a>
                            <a v-perm="'btn:workshop-model-delete'" @click="deleteRow(record.id)">
                                删除
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content" v-show="!isSelect">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                @click="() => openUpDateModal('')"
                                v-perm="'btn:workshop-model-add'"
                            >
                                新增
                            </a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import Detail from "./detail";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiProductUnitList,
    apiProductUnitTree,
    apiProductUnitDelete,
    apiProductUnitEnable,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        Detail,
    },
    props: {
        isSelect: {
            default: true,
        },
    },
    emits: ["select"],
    setup(props, { emit }) {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "生产单元编码",
                dataIndex: "code",
            },
            {
                title: "生产单元名称",
                dataIndex: "name",
            },
            {
                title: "生产单元类别",
                dataIndex: "categoryName",
            },
            {
                title: "父级生产单元",
                dataIndex: "parentName",
            },
            {
                title: "负责人",
                dataIndex: "principalDisplayName",
            },
        ];

        const state = reactive({
            detailId: null,
            upDateId: null,
            params: null,
            data: [],
            loading: false,
            keyWords: "",
            treeData: [],
            pId: "",
            comColumns: computed(() => {
                let _columns = [...columns];
                _columns = !props.isSelect
                    ? [
                          ..._columns,
                          {
                              title: "启用状态",
                              dataIndex: "enabled",
                              key: "enabled",
                              width: 100,
                          },
                          {
                              title: "操作",
                              dataIndex: "operate",
                              fixed: "right",
                              width: 200,
                          },
                      ]
                    : _columns;
                return _columns;
            }),
            selectedRowKeys: [],
            selectedRows: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiProductUnitList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    pId: state.pId,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data?.total;
                state.data = res.data?.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const openUpDateModal = id => {
            state.upDateId = id;
        };

        const getTreeData = async () => {
            let res = await apiProductUnitTree();
            if (res.status === "SUCCESS") {
                state.treeData = [res?.data];
            }
        };
        getTreeData();

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "生产单元禁用后，之后将无法在业务流程中使用该生产单元信息，是否确认禁用？"
                : "生产单元启用后，该生产单元可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiProductUnitEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "生产单元删除后将无法恢复，是否确认删除该生产单元？",
                onOk: async () => {
                    let res = await apiProductUnitDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const getNameById = (id, arr) => {
            let name;
            for (let item of arr) {
                if (item?.id == id) {
                    name = item.name;
                    return name;
                } else {
                    if (item?.children && item?.children.length) {
                        name = getNameById(id, item.children);
                        if (name !== undefined && name !== "") {
                            return name;
                        }
                    }
                }
            }
        };

        const handleSearchTree = value => {
            if (!value) return getTreeData();
            let res = [];
            const cycleSearch = treeData => {
                for (let item of treeData) {
                    if (item.name === value) {
                        res = [item];
                        return res;
                    } else if (item?.children && item?.children?.length) {
                        res = cycleSearch(item?.children);
                        if (res !== undefined) {
                            return res;
                        }
                    }
                }
            };
            state.treeData = cycleSearch(state.treeData);
        };

        const handleSelectTree = (key, e) => {
            state.pId = e.selected ? e?.node?.id : "";
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
            emit("select", selectedRows);
        };

        return {
            ...toRefs(state),
            openUpDateModal,
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            getTabelData,
            getTreeData,
            handleSearchTree,
            handleSelectTree,
            onSelectChange,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.ant-input-group-wrapper {
    margin-bottom: 10px;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        .tree-list {
            width: 200px;
            height: 200px;
            margin-right: 10px;
        }
        .ant-table-wrapper {
            flex: 1;
            width: 0;
        }
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
